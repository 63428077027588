import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { flowRight, isEmpty } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import Post from '../../../common/components/post';
import PrintWrapper from '../../../common/components/print-wrapper';
import { connect } from '../../../common/components/runtime-context';
import withAuth, { WithAuth } from '../../../common/hoc/with-auth';
import withCardBorderWidth, {
  WithCardBorderWidth,
} from '../../../common/hoc/with-card-border-width';
import withDeviceType, {
  WithDeviceType,
} from '../../../common/hoc/with-device-type';
import withFeedMetadataSettings, {
  WithFeedMetadataSettings,
} from '../../../common/hoc/with-feed-metadata-settings';
import withFontClassName, {
  WithFontClassName,
} from '../../../common/hoc/with-font-class-name';
import useOnScreen from '../../../common/hooks/use-on-screen';
import { getRoute } from '../../../common/router/router-selectors';
import { getPostByIdOrSlug } from '../../../common/selectors/post-selectors';
import { isInPostPreview } from '../../../common/services/detect-route';
import { isInWix } from '../../../common/services/is-in-wix';
import scrollToContent from '../../../common/services/scroll-to-content';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  getCommentId,
  isEditor as getIsEditor,
  isSeo as getIsSeo,
  isSSR as getIsSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import {
  getIsPostLoaded,
  getIsRecentPostsLoaded,
} from '../../../common/store/is-loaded/is-loaded-selectors';
import { NormalizedPost } from '../../../common/types';
import PostPageLayout from '../../components/post-page-layout';
import ReadingTimeListener, {
  OnScrollHandler,
  OnTabVisibilityChangeHandler,
} from '../../components/reading-time-listener/use-reading-time-listener';
import { POST_PAGE_COMMENTS_ON_SCREEN_THRESHOLD } from '../../constants/post-page';
import { RoutePostParams } from '../../constants/routes';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { PostPageWixComments } from './post-page-wix-comments-safe';
import RelevantPosts from './relevant-posts';
import styles from './post-page.scss';

type OwnProps = {
  params: RoutePostParams;
  location: Location;
};

type Props = OwnProps &
  WithFeedMetadataSettings &
  WithDeviceType &
  WithFontClassName &
  WithCardBorderWidth &
  WithTranslation &
  WithAuth &
  ConnectedProps<typeof connector>;

const PostPage: React.FC<Props> = (props) => {
  const [forceComments, setForceComments] = React.useState(false);
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const {
    contentFontClassName,
    isPostLoaded,
    isSSR,
    location,
    post,
    relatedPostsLabelKey,
    showRecentPosts,
    showRelatedPosts,
  } = props;

  const biElement = useBiElement({
    post,
    biActiveTabChanged: props.biActiveTabChanged,
    biPostScrolled: props.biPostScrolled,
    readingSessionId: props.readingSessionId,
    slug: props.postSlug,
    trackEvent: props.trackEvent,
    onSlugChange: () => {
      scrollToContent(styles.postPage);
    },
  });

  return (
    <PostPageLayout
      className={classNames(styles.postPage, contentFontClassName)}
      data-hook="post-page"
    >
      {!isSSR && !isInWix() && biElement}
      <AnimatedLoader isLoading={!isPostLoaded && isEmpty(post)}>
        <div className={styles.post}>
          <PrintWrapper>
            <Post
              key={resolveId(post)}
              post={post}
              location={location}
              isInPostPage
              onRatingsDisplayClick={() => {
                if (!forceComments) {
                  setForceComments(true);
                }

                requestAnimationFrame(() => {
                  commentsRef.current?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                  });
                });
              }}
            />
          </PrintWrapper>
          <AppLoaded key={post?.slug} />
        </div>
        <RelevantPosts
          post={post}
          showRecentPosts={showRecentPosts}
          showRelatedPosts={showRelatedPosts}
          relatedPostsLabelKey={relatedPostsLabelKey}
        />
        <CommentsSection
          {...props}
          commentsRef={commentsRef}
          forceComments={forceComments}
        />
      </AnimatedLoader>
    </PostPageLayout>
  );
};

const CommentsSection: React.FC<
  Omit<Props, 'children'> & {
    commentsRef: React.RefObject<HTMLDivElement>;
    forceComments?: boolean;
  }
> = ({
  post,
  showComments,
  commentId,
  isSeo,
  isSSR,
  isEditor,
  commentsRef,
  forceComments,
  initWixCommentsController,
}) => {
  const canSeePost = post?.canSeePaidContent !== false;
  const renderComments = !isSeo && canSeePost && showComments && post?.id;
  const { isOnScreen, ref } = useOnScreen(undefined, isEditor);

  if (!renderComments) {
    return null;
  }

  if (!isSSR && commentId) {
    initWixCommentsController();

    return (
      <div ref={commentsRef}>
        <Suspense fallback={<AnimatedLoader isLoading />}>
          <PostPageWixComments post={post} />
        </Suspense>
      </div>
    );
  }

  if (!isOnScreen && !forceComments) {
    return <div ref={ref as any} />;
  }

  initWixCommentsController();

  return (
    <div ref={commentsRef}>
      <Suspense fallback={<AnimatedLoader isLoading />}>
        <PostPageWixComments post={post} />
      </Suspense>
    </div>
  );
};

type PostPageBiParams = {
  biActiveTabChanged: (payload: any) => void;
  biPostScrolled: (payload: any) => void;
  post?: NormalizedPost;
  readingSessionId: string;
  slug?: string;
  trackEvent: (eventName: string, params: any) => void;
  onSlugChange: () => void;
};

const useBiElement = ({
  biActiveTabChanged,
  biPostScrolled,
  onSlugChange,
  post,
  readingSessionId,
  slug,
  trackEvent,
}: PostPageBiParams) => {
  const lastSlugRef = useRef(slug);
  const slugOnChangeRef = useRef(onSlugChange);

  const blogPostViewTrackEvent = useCallback(() => {
    trackEvent('BlogPostView', {
      event: 'BlogPostView',
      eventCategory: 'Wix Blog',
      eventAction: 'BlogPostView',
      eventLabel: post?.title,
      origin: 'Wix Blog',
    });
  }, [post?.title, trackEvent]);

  useEffect(() => {
    blogPostViewTrackEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSlugRef.current !== slug && slug) {
      lastSlugRef.current = slug;
      blogPostViewTrackEvent();
      slugOnChangeRef.current();
    }
  }, [slug, blogPostViewTrackEvent]);

  const handleScroll: OnScrollHandler = useCallback(
    (event) => {
      biPostScrolled({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biPostScrolled],
  );

  const handleTabVisibilityChange: OnTabVisibilityChangeHandler = useCallback(
    (event) => {
      biActiveTabChanged({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biActiveTabChanged],
  );

  return (
    <ReadingTimeListener
      getContentContainer={getPostContentContainer}
      onScroll={handleScroll}
      onTabVisibilityChange={handleTabVisibilityChange}
      readingSessionId={readingSessionId}
    />
  );
};

const getPostContentContainer = () => {
  return document.querySelector('[data-hook="post"]')?.getBoundingClientRect();
};

const connector = connect((state, ownProps: OwnProps, actions) => {
  const postSlug = resolvePostSlug(ownProps.params);
  const useDraft = isInPostPreview(getRoute(state));
  let post = getPostByIdOrSlug(state, postSlug);

  post = useDraft ? { ...post, ...(post?.draft || {}) } : post;

  const postId = resolveId(post);
  return {
    post,
    postSlug,

    isEditor: getIsEditor(state),
    isPostLoaded: getIsPostLoaded(state, postSlug),
    isRecentPostsLoaded: getIsRecentPostsLoaded(state, postId),

    isSeo: getIsSeo(state),
    isSSR: getIsSSR(state),
    commentId: getCommentId(state),
    readingSessionId: getReadingSessionId(state),

    trackEvent: actions.trackEvent,
    biPostScrolled: actions.biPostScrolled,
    biActiveTabChanged: actions.biActiveTabChanged,
    initWixCommentsController: actions.initWixCommentsController,
  };
});

export default flowRight(
  connector,
  withFeedMetadataSettings,
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslation(),
)(PostPage);
