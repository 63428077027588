import { flowRight, get } from 'lodash';
import {
  getLayoutName,
  POST_LIST_COMPONENT_MASONRY,
  SEO_LAYOUT_TYPE_FALLBACK,
  MobileLayoutType,
  LayoutType,
} from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';
import {
  getLayoutType,
  isMasonryLayoutSupported,
} from '../selectors/layout-selectors';
import { getSection } from '../selectors/section-selectors';
import { getLayoutConfig } from '../services/layout-config';
import { AppState } from '../types';
import withHocName from './with-hoc-name';

export type WithLayoutOwnProps = {
  section?: ReturnType<typeof getSection>;
  layoutType?: LayoutType;
  mobileLayoutType?: MobileLayoutType;
};

export type WithLayoutProps = ReturnType<ReturnType<typeof mapRuntimeToProps>>;

const mapRuntimeToProps =
  (fallbackEnabled: boolean) =>
  (
    state: AppState,
    { section, layoutType, mobileLayoutType }: WithLayoutOwnProps,
  ) => {
    section ||= getSection(state, fallbackEnabled);
    layoutType ||= getLayoutType(state, section);
    mobileLayoutType ||= getLayoutType(state, section, true);

    if (
      !isMasonryLayoutSupported(state) &&
      get(getLayoutConfig(layoutType), 'listComponentName') ===
        POST_LIST_COMPONENT_MASONRY
    ) {
      layoutType = SEO_LAYOUT_TYPE_FALLBACK;
    }

    return {
      section,
      layoutType,
      mobileLayoutType,
      layoutName: getLayoutName(layoutType as LayoutType),
      mobileLayoutName: getLayoutName(mobileLayoutType as MobileLayoutType),
    };
  };

export default function withLayoutProps({ fallbackEnabled = true } = {}) {
  return flowRight(
    connect(mapRuntimeToProps(fallbackEnabled)),
    withHocName('WithLayoutProps'),
  );
}
