import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { GatewayProvider, GatewayDest } from 'react-gateway';

import { getIsPostPageBundle } from '@wix/communities-blog-client-common';

import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { isInWix } from '../../services/is-in-wix';
import { isEditor } from '../../store/basic-params/basic-params-selectors';
import { AccessibilityListener } from '../accessibility-listener';
import AppWrapper from '../app-wrapper';
import { connect } from '../runtime-context';
import styles from './app.scss';

export class AppMobile extends Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  render() {
    const {
      createdWithResponsiveEditor,
      main,
      modalRoot,
      messageRoot,
      popoverRoot,
      children,
      mobileHeader,
      mobileMenu,
    } = this.props;
    return (
      <GatewayProvider>
        <AppWrapper
          className={classNames(
            styles.app,
            'app-mobile',
            'blog-background-color',
          )}
        >
          <AccessibilityListener />
          {messageRoot}
          {modalRoot}
          {popoverRoot}
          <div
            id="content-wrapper"
            className={classNames(styles.scrollable, 'app-mobile__scrollable')}
          >
            {mobileHeader &&
              React.cloneElement(mobileHeader, {
                menu: mobileMenu,
                hideNavBar: createdWithResponsiveEditor,
              })}
            {main && React.cloneElement(main, { children })}
          </div>
          <GatewayDest name="tooltip" />
        </AppWrapper>
      </GatewayProvider>
    );
  }
}

AppMobile.propTypes = {
  categories: PropTypes.array,
  createdWithResponsiveEditor: PropTypes.bool,
  children: PropTypes.node,
  isEditor: PropTypes.bool,
  isPostPageBundle: PropTypes.bool,
  main: PropTypes.node,
  modalRoot: PropTypes.node.isRequired,
  messageRoot: PropTypes.node.isRequired,
  popoverRoot: PropTypes.node.isRequired,
  mobileHeader: PropTypes.node,
  mobileMenu: PropTypes.node,
};

const mapRuntimeToProps = (state) => ({
  isEditor: isEditor(state),
  categories: state.categories,
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isPostPageBundle: getIsPostPageBundle(state),
});

export default flowRight(connect(mapRuntimeToProps))(AppMobile);
