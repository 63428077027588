import React from 'react';
import { ROUTE_COMPONENT_MAP } from './route-component-map';
import { useMatchedRoute } from './use-matched-route';

const Router: React.FC = () => {
  const matchedRouteParams = useMatchedRoute();
  const Component =
    ROUTE_COMPONENT_MAP[matchedRouteParams.route] ??
    raise(`Route ${matchedRouteParams.route} not implemented`);
  return <Component {...matchedRouteParams} />;
};

const raise = (message: string): never => {
  throw new Error(message);
};

export default Router;
