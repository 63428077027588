import { getTPASettingsIsPostPageSplitEnabled } from '@wix/communities-blog-client-common';
import { useSelector } from '../../../common/components/runtime-context';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import { ROUTE_COMPONENT_MAP } from './route-component-map';

export type MatchedRoute = {
  route: keyof typeof ROUTE_COMPONENT_MAP;
  pathname: string;
  sectionUrl: string;
  params: any;
  isPostPageSplitEnabled: boolean;
};

export const useMatchedRoute = () =>
  useSelector((state): MatchedRoute => {
    const { route, pathname, sectionUrl, params } = getCurrentMatch(state);
    return {
      route: route as keyof typeof ROUTE_COMPONENT_MAP,
      pathname,
      sectionUrl,
      params,
      isPostPageSplitEnabled: getTPASettingsIsPostPageSplitEnabled(state),
    };
  });
